<template>
    <div class="contain">
        <div class="contain-title">赛前准备</div>
        <div class="contain-content">
            赛事名称: {{eventInfo.eventName}}
            <br><br>
            赛事简介: {{eventInfo.eventDesc}}
        </div>
        <div class="contain-choose">
            <span>选择准备时显示的内容: &nbsp;&nbsp;</span>
            <el-checkbox-group v-model="checkList" @change="changeStatus">
                <el-checkbox :label="1">赛事封面</el-checkbox>
                <el-checkbox :label="2">评委信息</el-checkbox>
                <el-checkbox :label="3">选手信息</el-checkbox>
            </el-checkbox-group>
        </div>

        <div class="contain-choose">
            <span>展示播放放松: &nbsp;&nbsp;</span>
            <el-radio-group v-model="radio" @change="changeStatus">
                <el-radio :label="1">自动播放</el-radio>
                <el-radio :label="2">手动播放</el-radio>
            </el-radio-group>
        </div>
        <div class="contain-btn">
            <el-button style="background-color: #bf353b;color: #fff" size="small" @click="getInDrawControl">
                开始抽签
            </el-button>
        </div>
    </div>
</template>

<script>
    import {findById, changeShowStatus, editEventStatus} from "../../api/event";

    export default {
        name: "hostPage",
        data() {
            return {
                checkList: [],
                radio: null,
                eventInfo: null,
                eventId: null,
                roleFlag:null
            };
        },
        created() {
            this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
            this.getEventInfo(this.eventInfo.id)
        },
        mounted() {
            //1管理 2评委 3主持 4大屏端
            this.roleFlag = sessionStorage.getItem('roleFlag');
        },
        methods: {
            async changeStatus() {
                if (this.checkList.length === 0) {
                    this.getEventInfo(this.eventInfo.id);
                    this.$message.warning('请至少选择一项展示内容');
                    return false
                }
                let obj = {
                    eventId: this.eventInfo.id,
                    beforeShowList: this.checkList,
                    playType: this.radio
                }
                const {data} = await changeShowStatus(obj)
                if (data.code === 200) {
                    await this.getEventInfo(this.eventInfo.id);
                }
            },
            getInDrawControl() {
                this.$confirm('开始抽签, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //改变抽签状态
                    let obj = {
                        eventId: this.eventInfo.id,
                        status: 2
                    }
                    editEventStatus(obj).then(res => {
                        if (res.data.code === 200) {
                            this.$router.push('/drawControl')
                        } else {
                            let data = res.data.data;
                            if (data && data === 2) {
                                this.$router.push('/drawControl')
                            }
                            if (data && data === 3) {
                                this.$router.push('/eventControl')
                            }
                            if (data && data === 4) {
                                this.$router.push('/showOver')
                            }
                        }
                    })
                })
            },
            //查询比赛信息
            async getEventInfo(id) {
                const {data} = await findById(id);
                if (data.code === 200) {
                    this.eventInfo = data.data
                    this.checkList = this.eventInfo.beforeShowList
                    this.radio = this.eventInfo.playType;
                    if (this.roleFlag === '3'){
                        if (this.eventInfo.startStatus===1){
                            this.$router.push('/hostPage')
                        }
                        if (this.eventInfo.startStatus===2){
                            this.$router.push('/drawControl')
                        }
                        if (this.eventInfo.startStatus===3){
                            this.$router.push('/eventControl')
                        }
                        if (this.eventInfo.startStatus===4){
                            this.$router.push('/eventOver')
                        }
                    }
                    if (this.roleFlag === '4'){
                        if (this.eventInfo.startStatus===1){
                            this.$router.push('/beforeShow')
                        }
                        if (this.eventInfo.startStatus===2){
                            this.$router.push('/drawSort')
                        }
                        if (this.eventInfo.startStatus===3){
                            this.$router.push('/eventShow')
                        }
                        if (this.eventInfo.startStatus===4){
                            this.$router.push('/eventShow')
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .contain {
        position: relative;
        margin: 0px;
        width: 100%;
        height: 100%;
        background: url("../../assets/images/hostBanner.png") no-repeat;
        background-size: 100% 100%;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .contain-title {
            width: 80%;
            margin: 20px 0;
            font-size: 20px;
            font-weight: bold;
        }

        .contain-content {
            font-family: '楷体';
            font-size: 24px;
            color: #000;
            margin: 20px 0;
            width: 30%;
            overflow: hidden;
        }

        .contain-choose {
            margin: 20px 0;
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 18px;
            font-weight: 500;
            color: #696969;

            /deep/ .el-radio__input.is-checked .el-radio__inner {
                border-color: #bf353b;
                background-color: #bf353b;
            }

            /deep/ .el-radio__input.is-checked + .el-radio__label {
                color: #bf353b;
            }

            /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                border-color: #bf353b;
                background-color: #bf353b;
            }

            /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
                color: #bf353b;
            }
        }

        .contain-btn {
            margin: 20px 0;
        }
    }
</style>
